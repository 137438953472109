import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import CodeMirror from 'codemirror';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/tern/tern';
import 'codemirror/mode/python/python';
import { MatDialog } from '@angular/material/dialog';
import { CodeEditorDialogComponent } from './code-editor-dialog.component';
import { XpModalService } from '../../../common/components/modals/xp-modal.service';
import { Subscription } from 'rxjs';

const LINE_HEIGHT = 20;
const PADDING = 20;
const MIN_HEIGHT = 130;
const MAX_HEIGHT = 700;

@Component({
  selector: 'code-editor',
  template: `
    <div class="code-editor">
      <button class="btn btn-primary open-code-editor-dialog" (click)="openCodeEditorDialog()">Open Code Editor</button>
      <textarea class="form-control" id="code-editor" [ngModel]="value" [attr.name]="name"></textarea>
    </div>
  `,
})
export class CodeEditorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value: string;
  @Input() name: string;
  @Input() options: any;
  @Input() tables: string[] = [];
  @Input() editorTitle: string;
  @Input() errorMessage: string;
  @Input() errorLine: number;
  @Output() valueChange = new EventEmitter<string>();

  textAreaElement: HTMLTextAreaElement;
  editor = null;
  editorOptions: any = {};
  scroll: HTMLElement;
  gutters: HTMLElement;
  fullScreenSubscription: Subscription;
  widgets: any[] = [];

  constructor(
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private xpModalService: XpModalService,
  ) {
    this.fullScreenSubscription = this.xpModalService.isFullScreen.subscribe((isFullScreen) => {
      if (isFullScreen) {
        this.setEditorSize(this.value?.split('\n').length || 13);
      }
    });
  }

  ngAfterViewInit() {
    this.textAreaElement = this.elementRef.nativeElement.querySelector('textarea');

    this.initEditor();

    setTimeout(() => {
      this.scroll = this.elementRef.nativeElement.querySelector('.CodeMirror-scroll');
      this.gutters = this.elementRef.nativeElement.querySelector('.CodeMirror-gutters');
      this.setEditorSize(this.value?.split('\n').length || 13);
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value?.currentValue !== changes.value?.previousValue &&
      !changes.value?.firstChange &&
      this.editor &&
      this.value !== this.editor.getValue()
    ) {
      this.editor.setValue(this.value);
      this.setEditorSize(this.value?.split('\n').length || 13);
    }

    if (changes.tables?.currentValue && this.editor) {
      const currentHintOptions = this.editor.getOption('hintOptions') || {};

      this.editor.setOption('hintOptions', {
        ...currentHintOptions,
        tables: {
          ...(currentHintOptions.tables || {}),
          ...this.tables.reduce((acc, table) => {
            acc[table] = [];
            return acc;
          }, {}),
        },
      });

      this.editorOptions = {
        ...this.editorOptions,
        hintOptions: {
          ...this.editorOptions.hintOptions,
          tables: {
            ...this.editorOptions.hintOptions.tables,
          },
        },
      };
    }

    if (changes.errorMessage?.currentValue && changes.errorLine?.currentValue) {
      const msg = document.createElement('div');
      msg.classList.add('lint-error');

      // Add exclamation triangle icon
      const triangleIcon = document.createElement('span');
      triangleIcon.classList.add('fa');
      triangleIcon.classList.add('fa-exclamation-triangle');
      msg.appendChild(triangleIcon);

      // Add error message
      const messageSpan = document.createElement('span');
      messageSpan.innerHTML = this.errorMessage;
      msg.appendChild(messageSpan);

      this.widgets.push(
        this.editor.addLineWidget(this.errorLine - 1, msg, {
          coverGutter: false,
          noHScroll: true,
        }),
      );
    }
  }

  clearErrors() {
    while (this.widgets.length > 0) {
      this.editor.removeLineWidget(this.widgets[0]);
      this.widgets.pop();
    }
  }

  initEditor() {
    const oldCodeMirror = document.querySelector('.CodeMirror');

    if (oldCodeMirror) {
      oldCodeMirror.parentElement.removeChild(oldCodeMirror);
    }

    setTimeout(() => {
      const defaultOptions = {
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/x-sql',
        placeholder: 'SELECT * FROM table',
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
        },
        hintOptions: {
          tables: {
            ...(this.tables || []).reduce((acc, table) => {
              acc[table] = [];
              return acc;
            }, {}),
          },
          completeSingle: false,
          completeOnSingleClick: true,
        },
      };

      const options = { ...defaultOptions, ...(this.options || {}) };

      this.editorOptions = options;

      this.editor = CodeMirror.fromTextArea(this.textAreaElement, options);

      if (this.value) this.editor.setValue(this.value);

      // Enable auto hints
      this.editor.on('keyup', (cm: CodeMirror.Editor, event: KeyboardEvent) => {
        if (
          !cm.state.completionActive &&
          !event.ctrlKey &&
          !event.altKey &&
          !event.metaKey &&
          /^[a-zA-Z.]$/.test(event.key)
        ) {
          CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
        }
      });

      this.editor.on('change', (cm) => {
        this.value = cm.getValue();

        this.valueChange.emit(this.value);
        this.clearErrors();
        this.textAreaElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });

      this.editor.on('blur', (cm) => {
        this.value = cm.getValue();
        this.valueChange.emit(this.value);
      });
    }, 0);
  }

  public openCodeEditorDialog() {
    const dialogRef = this.dialog.open(CodeEditorDialogComponent, {
      data: {
        value: this.value,
        tables: this.tables,
        options: this.editorOptions,
        name: this.name,
        cursorPosition: this.editor?.getCursor(),
        title: this.editorTitle || 'SQL Editor',
      },
      position: {
        top: '50px',
      },
      maxWidth: 'calc(100vw - 200px)',
      maxHeight: 'calc(100vh - 200px)',
      width: '100%',
      height: '100%',
      panelClass: 'code-editor-dialog',
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (!data) return;

      const { value, cursorPosition } = data;

      if (value) {
        this.value = value;
        this.valueChange.emit(this.value);
      }
      this.editor.focus();

      if (cursorPosition) {
        this.editor.setCursor(cursorPosition);
      }
    });
  }

  setEditorSize(numberOfLines: number) {
    if (this.editor && this.editor.setSize) {
      this.scroll = this.elementRef.nativeElement.querySelector('.CodeMirror-scroll');
      const calculatedHeight = numberOfLines * LINE_HEIGHT + PADDING;
      const codeEditorHeight = Math.min(Math.max(calculatedHeight, MIN_HEIGHT), MAX_HEIGHT);

      this.gutters.style.minHeight = `${codeEditorHeight}px`;
      this.scroll.style.minHeight = `${codeEditorHeight}px`;
      this.scroll.style.maxHeight = `${codeEditorHeight}px`;
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.toTextArea();
    }

    if (this.fullScreenSubscription) {
      this.fullScreenSubscription.unsubscribe();
    }
  }
}
