import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { AuthMethod, SnowflakeConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { RegionsResource } from '../../resources/regions.resource';

export const DUMMY_PASSWORD = '_DUMMY_PASSWORD_';

@Component({
  selector: 'connection-form-snowflake',
  template: `
    <xp-form-validation type="SnowflakeDatabaseConnection" [name]="formName">
      <form id="connectionFormSnowflake" name="connectionFormSnowflake" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <xp-form-group>
              <label for="account_name">{{ 'connections.form.labels.account_name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="account_name"
                id="account_name"
                [(ngModel)]="connection.account_name"
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="database">{{ 'connections.form.labels.database' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="warehouse">{{ 'connections.form.labels.warehouse' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="warehouse"
                id="warehouse"
                [(ngModel)]="connection.warehouse"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group>
              <label for="region">{{ 'connections.form.athena.labels.region' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                id="region"
                name="region"
                [value]="connection.region"
                [options]="snowflakeRegions"
                [isGrouped]="true"
                [isSearchEnabled]="true"
                panelClass="connection-select-box"
                (valueChange)="onRegionChange($event)"
                emptyPlaceholder="No region found"
              >
              </xp-select>
            </xp-form-group>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="auth_method">{{ 'connections.form.sftp.labels.auth_method' | translate }}</label>
              <xp-select
                class="form-control xp-select"
                name="auth_method"
                id="auth_method"
                [value]="connection.auth_method"
                (valueChange)="onAuthMethodChange($event)"
                [options]="authMethodOptions"
              >
              </xp-select>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.auth_method === 'key'">
              <div class="files-input-container">
                <xp-form-group>
                  <label for="snowflake_private_key">Private key <span class="text-muted">(.p8)</span></label>
                  <input
                    style="display: none"
                    type="file"
                    (change)="onFileChangedKey($event)"
                    id="snowflake_private_key"
                    name="snowflake_private_key"
                    #fileInput
                  />
                  <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                    {{ selectedFileKey ? 'Select another file' : 'Select file' }}
                  </button>
                  <span class="file-name" *ngIf="selectedFileKey">{{ selectedFileKey.name | xpLengthCheck: 28 }}</span>
                  <span class="error-message" *ngIf="errorKey">{{ errorKey }}</span>
                </xp-form-group>
              </div>
              <xp-form-group>
                <label for="snowflake_private_key_password">
                  Private key passphrase
                  <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span>
                  <span *ngIf="passphraseChangeable">
                    (<a (click)="changePassphrase()"
                      ><span *ngIf="passphraseDisabled">{{ 'connections.form.labels.change' | translate }}</span
                      ><span *ngIf="!passphraseDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
                    >)
                  </span>
                </label>
                <xp-input
                  type="password"
                  class="form-control"
                  name="snowflake_private_key_password"
                  id="snowflake_private_key_password"
                  [attr.disabled]="passphraseDisabled"
                  [disable]="passphraseDisabled"
                  [(ngModel)]="connection.snowflake_private_key_password"
                ></xp-input>
              </xp-form-group>
            </div>

            <connection-component-username-password [connection]="connection"></connection-component-username-password>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormSnowflakeComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<SnowflakeConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormSnowflake';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  snowflakeRegions = [];
  authMethodOptions = [
    { value: AuthMethod.password, text: 'Password authentication' },
    { value: AuthMethod.key, text: 'Key-pair authentication' },
  ];
  errorKey = '';
  selectedFileKey: File | null = null;

  passphraseChangeable = false;
  passphraseDisabled = true;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,

    private regionsResource: RegionsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.regionsResource.query('snowflake').subscribe((snowflakeRegions) => {
      this.snowflakeRegions = snowflakeRegions.map(({ id, short_name, group_name }) => ({
        text: short_name,
        value: id,
        group_name,
      }));
    });

    this.passphraseChangeable = !!this.connection.id;
    this.passphraseDisabled = this.passphraseChangeable;

    if (this.passphraseDisabled) {
      this.connection.snowflake_private_key_password = DUMMY_PASSWORD;
    }
  }

  onAuthMethodChange(authMethod: AuthMethod) {
    this.connection.auth_method = authMethod;
  }

  onRegionChange(region: string) {
    this.connection.region = region;
  }

  onFileChangedKey(event: Event) {
    this.errorKey = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFileKey = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (!this.selectedFileKey?.name.toLowerCase().endsWith('.p8')) {
      this.errorKey = 'Selected file must be a .p8 private key file.';
      this.selectedFileKey = null;
      return;
    }
    fileReader.readAsText(this.selectedFileKey, 'UTF-8');

    fileReader.onload = () => {
      try {
        this.connection.snowflake_private_key = fileReader.result as string;
      } catch (e) {
        this.errorKey = e;
      }
    };

    fileReader.onerror = (error) => {
      this.errorKey = JSON.stringify(error);
    };
  }

  changePassphrase(): void {
    this.passphraseDisabled = !this.passphraseDisabled;

    if (this.passphraseDisabled) {
      this.connection.snowflake_private_key_password = DUMMY_PASSWORD;
    } else {
      this.connection.snowflake_private_key_password = '';
    }
  }
}
